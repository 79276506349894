import React, { useEffect, useState, Suspense } from 'react';
import Footer from './Footer';

const NavPublic = React.lazy(() => import('./NavPublic'));

function About() {

    return (
        <>   
        <section className="theme-light section m-0 p-0" data-theme="light">
            <div className="m-0 p-0 is-flex is-flex-direction-column is-justify-content-space-between">
                <div className="is-flex is-flex-direction-column">
                    {/* Navigation */}
                    <div className="p-6 m-0">
                        <Suspense fallback={<div>Loading...</div>}>
                        <NavPublic colorView="light" />
                        </Suspense>
                    </div>
        
                    {/* Header and Filters */}
                    <div className="container">
                        <div className="m-0 px-0 pb-0 pt-6 is-flex is-flex-direction-columns is-justify-content-space-between is-align-items-center">
                            <div className="m-0 p-0 m-0">
                                <p className="is-size-3 has-text-weight-bold colorBlack2">
                                About 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
        <section className='section m-0 p-0'>
            <Footer />
        </section>
        </>
    );
}

export default About;