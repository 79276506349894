import React from 'react';
import Footer from './Footer';

function Experiments() {


  return (
    <>   
      <section className="section m-0 p-0" data-theme="dark">
        <div className="m-0 p-0">
          <div className="fullHeight is-flex is-flex-direction-column">
          </div>
        </div>
      </section>
      
      <section className='section m-0 p-0'>
        <Footer />
      </section>
    </>
  );
}

export default Experiments;