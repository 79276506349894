import React from 'react';
import { Routes as RouterRoutes, Route, Navigate, useLocation } from 'react-router-dom';

import Homepage from '../components/Homepage';
import Login from '../components/Login';
import About from '../components/About';
import Services from '../components/Services';
import Clients from '../components/Clients';
import Contact from '../components/Contact';
import Research from '../components/Research';
import Experiments from '../components/Experiments';
import Downloads from '../components/Downloads';
import Cases from '../components/Cases';
import CaseWuwta from '../components/CaseWuwta';
import Case3m from '../components/Case3m';
import CaseHawaiian from '../components/CaseHawaiian';
import CaseBiogen from '../components/CaseBiogen';
import CaseNudgemail from '../components/CaseNudgemail';
import ExperimentSegmind from '../components/ExperimentSegmind';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        // Redirect to login if there's no token
        return <Navigate to="/login" replace />;
    }
    return children;
};

// Public Route Component (only accessible if NOT logged in)
const PublicRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    if (token) {
        // Redirect to Today if already logged in
        return <Navigate to="/" replace />;
    }
    return children;
};

const Routes = () => {
    const location = useLocation();
    
    React.useEffect(() => {
        // Remove all page-specific classes
        document.documentElement.classList.remove('backgroundColorBlack5', 'backgroundColorBlack1');
        
        // Add the appropriate class based on the current path
        switch(location.pathname) {
            case '/contact':
            case '/about': 
            case '/':           
                document.documentElement.classList.add('backgroundColorBlack5');
                break;
            default:
                document.documentElement.classList.add('backgroundColorBlack1');
                break;
        }
    }, [location]);

    return (
        <RouterRoutes>
            <Route path="/" element={
                <PublicRoute>
                    <Homepage />
                </PublicRoute>} 
            />
            <Route path="/login" element={
                <PublicRoute>
                    <Login />
                </PublicRoute>} 
            />
            <Route path="/about" element={
                <PublicRoute>
                    <About />
                </PublicRoute>} 
            />
            <Route path="/services" element={
                <PublicRoute>
                    <Services />
                </PublicRoute>} 
            />
            <Route path="/clients" element={
                <PublicRoute>
                    <Clients />
                </PublicRoute>} 
            />
            <Route path="/contact" element={
                <PublicRoute>
                    <Contact />
                </PublicRoute>} 
            />
            <Route path="/research" element={
                <PublicRoute>
                    <Research />
                </PublicRoute>} 
            />
            <Route path="/experiments" element={
                <PublicRoute>
                    <Experiments />
                </PublicRoute>} 
            />
            <Route path="/downloads" element={
                <PublicRoute>
                    <Downloads />
                </PublicRoute>} 
            />
            <Route path="/cases" element={
                <PublicRoute>
                    <Cases />
                </PublicRoute>} 
            />

            <Route path="/wuwta" element={
                <ProtectedRoute>
                    <CaseWuwta />
                </ProtectedRoute>
            } />
            <Route path="/3m" element={
                <ProtectedRoute>
                    <Case3m />
                </ProtectedRoute>
            } />
            <Route path="/hawaiian" element={
                <ProtectedRoute>
                    <CaseHawaiian />
                </ProtectedRoute>
            } />
            <Route path="/biogen" element={
                <ProtectedRoute>
                    <CaseBiogen />
                </ProtectedRoute>
            } />
            <Route path="/nudgemail" element={
                <ProtectedRoute>
                    <CaseNudgemail />
                </ProtectedRoute>
            } />
            <Route path="/segmind" element={
                <ProtectedRoute>
                    <ExperimentSegmind />
                </ProtectedRoute>
            } />
            
            <Route path="*" element={<Navigate to="/?utm_source=404" replace />} />
        </RouterRoutes>
    );
};

export default Routes;