import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './HomeStatement.css';

const HomeStatement = () => {
    const defaultStatement = 'Hi. How are you doing?';
    const [fullStatement, setFullStatement] = useState('');
    const [displayedText, setDisplayedText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const typingTimerRef = useRef(null);
    const typingSpeed = 50; // ms per character

    // Fetch statement from MongoDB
    useEffect(() => {
        const fetchGreeting = async () => {
            try {
                setIsLoading(true);
                
                // Get the current hour
                const currentHour = new Date().getHours();
                
                // Determine time of day for filtering
                let timeOfDay = 'any';
                if (currentHour >= 5 && currentHour < 12) {
                    timeOfDay = 'morning';
                } else if (currentHour >= 12 && currentHour < 17) {
                    timeOfDay = 'afternoon';
                } else if (currentHour >= 17 && currentHour < 22) {
                    timeOfDay = 'night';
                } else {
                    timeOfDay = 'lateNight';
                }
                
                // Call our backend API to get a random greeting
                const response = await axios.get(`/api/greeting?timeOfDay=${timeOfDay}`, {
                    timeout: 5000 // 5 seconds timeout
                });
                
                if (response.data && response.data.greeting) {
                    // Set the full statement
                    setFullStatement(response.data.greeting);
                } else {
                    console.warn('Response missing greeting:', response.data);
                    setFullStatement(defaultStatement);
                }
            } catch (err) {
                console.error('Error fetching greeting:', err);
                setFullStatement(defaultStatement);
            } finally {
                setIsLoading(false);
            }
        };

        fetchGreeting();
        
        // Cleanup function
        return () => {
            if (typingTimerRef.current) {
                clearTimeout(typingTimerRef.current);
            }
        };
    }, []);

    // Simple typing effect
    useEffect(() => {
        if (!isLoading && fullStatement) {
            let currentIndex = 0;
            setDisplayedText('');
            
            const typeNextCharacter = () => {
                if (currentIndex < fullStatement.length) {
                    setDisplayedText(fullStatement.substring(0, currentIndex + 1));
                    currentIndex++;
                    typingTimerRef.current = setTimeout(typeNextCharacter, typingSpeed);
                }
            };
            
            typingTimerRef.current = setTimeout(typeNextCharacter, typingSpeed);
            
            return () => {
                if (typingTimerRef.current) {
                    clearTimeout(typingTimerRef.current);
                }
            };
        }
    }, [isLoading, fullStatement]);

    return (
        <div className='container py-6 home-statement-container'>
            <div className='m-0 px-0 pt-3 pb-6'>
                {isLoading ? (
                    <p className='h3 has-text-weight-bold'>Loading...</p>
                ) : (
                    <p className='h3 has-text-weight-bold'>
                        <span className="typing-text">{displayedText}</span>
                        {displayedText.length < fullStatement.length && (
                            <span className="cursor"></span>
                        )}
                    </p>
                )}
            </div>
        </div>
    );
};

export default HomeStatement;